<template>
  <div>
    <b-card>
      <h1>Nowy pracownik</h1>
    </b-card>
    <b-card>
      <b-form action="/sd">
        <div>
          <b-form-input
            v-model="employee.email"
            placeholder="Email"
            class="mt-2"
          />
        </div>

        <div>
          <b-form-input
            v-model="employee.name"
            type="text"
            placeholder="Nazwa"
            class="mt-2"
          />
        </div>

        <div>
          <b-form-input
            v-model="employee.phone_number"
            type="email"
            placeholder="Numer telefonu"
            class="mt-2"
          />
        </div>

        <div>
          <b-form-input
            v-model="employee.description"
            type="text"
            placeholder="Opis"
            class="mt-2"
          />
        </div>

        <div>
          <b-form-input
            v-model="employee.password"
            type="password"
            placeholder="Jeśli chcesz zmienić hasło wpisz je tu..."
            class="password mt-2"
          />
        </div>

        <div>
          <b-form-file
            id="image"
            type="file"
            class="image mt-2"
            placeholder="Zdjęcie profilowe"
          />
        </div>
      </b-form>
      <b-button
        class="submit"
        variant="primary"
        @click="submit()"
      >
        Zapisz nowego pracownika
      </b-button>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardText, BFormInput, BForm, BFormGroup, BFromLabel, BFormFile, BButton, BFormE,
} from 'bootstrap-vue'
import swal from 'sweetalert2'
import axios from 'axios'

export default {
  components: {
    BCard,
    BCardText,
    BFormInput,
    BForm,
    BFormGroup,
    BFromLabel,
    BFormFile,
    BButton,
  },
  data() {
    return {
      employee: {
        email: '',
        name: '',
        phone_number: '',
        password: '',
        description: '',
      },
    }
  },
  mounted() {
    document.title = 'Nowy pracownik - Pickmode'
  },
  methods: {
    submit() {
      // send data to server with image
      const formData = new FormData()
      formData.append('image', document.querySelector('#image').files[0])
      formData.append('email', this.employee.email)
      formData.append('name', this.employee.name)
      formData.append('phone_number', this.employee.phone_number)
      formData.append('password', this.employee.password)
      formData.append('description', this.employee.description)
      axios.post(`${process.env.VUE_APP_API_URL}employee`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
        .then(response => {
          swal.fire({
            title: 'Udało się!',
            text: 'Pracownik został dodany',
            type: 'success',
            confirmButtonText: 'OK',
            icon: 'success',
          })

          this.$router.push('/Pracownicy')
        })
        .catch(error => {
          swal.fire({
            title: 'Błąd!',
            text: 'Prosimy sprawdzić ponownie formularz.',
            type: 'error',
            icon: 'info',
            confirmButtonText: 'OK',
          })
        })
    },
  },

}
</script>

<style>
.submit {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(204, 204, 204, 0.344);
    border-radius: 5px;
    background: rgba(204, 204, 204, 0.468);
    margin-top: 10px;
}
::placeholder {
    color: rgb(255, 255, 255);
}
</style>
